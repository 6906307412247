var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-screen" },
    [
      _c("div", { staticClass: "container mx-auto" }, [
        _vm._m(0),
        _vm._m(1),
        _c(
          "div",
          { staticClass: "w-6/12 px-4 mt-6 mb-2 mx-auto" },
          [
            _c(
              "LinkButton",
              {
                attrs: { classList: "px-2 py-4 text-center text-lg" },
                on: { clickButton: _vm.downloadCsv },
              },
              [_vm._v("エクセルファイルのダウンロード")]
            ),
          ],
          1
        ),
        _vm._m(2),
        _vm._m(3),
        _c(
          "div",
          {
            staticClass:
              "w-full mt-6 mb-4 border-dashed border-2 border-very-light-grey grid grid-cols-12 gap-x-6",
            class: [_vm.isDrag ? "bg-gray-200" : "bg-white"],
            on: {
              dragover: function ($event) {
                $event.preventDefault()
                return _vm.checkDrag($event, true)
              },
              dragleave: function ($event) {
                $event.preventDefault()
                return _vm.checkDrag($event, false)
              },
              drop: function ($event) {
                $event.preventDefault()
                return _vm.inputFile($event, "drop")
              },
            },
          },
          [
            _vm._m(4),
            _c("div", { staticClass: "col-span-4 my-8 text-center" }, [
              _vm._m(5),
              _c(
                "button",
                {
                  staticClass:
                    "px-2 py-3 mb-2 mx-auto text-sm font-semibold border border-brown-grey",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.file.click()
                    },
                  },
                },
                [_vm._v(" エクセルファイルを選択 ")]
              ),
              _c(
                "div",
                { staticClass: "ml-2 text-xxs font-semibold tracking-widest" },
                [_vm._v(" エクセルファイルをドラッグするか選択してください。 ")]
              ),
              _c(
                "div",
                { staticClass: "ml-2 text-xxs font-semibold text-brown-grey" },
                [_vm._v("最大アップロードサイズ: 40MB")]
              ),
              _c("input", {
                ref: "file",
                staticClass: "hidden",
                attrs: {
                  type: "file",
                  accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                },
                on: {
                  change: function ($event) {
                    return _vm.inputFile($event, "select")
                  },
                },
              }),
            ]),
          ]
        ),
        _vm.movies.length > 0
          ? _c(
              "div",
              {
                staticClass: "w_full border-2 border-t border-very-light-grey",
              },
              [
                _c(
                  "div",
                  { staticClass: "w-6/12 px-4 my-6 mx-auto" },
                  [
                    _c(
                      "LinkButton",
                      {
                        attrs: {
                          classList:
                            "px-2 py-4 text-center text-lg bg-very-base-yallow disabled:bg-very-light-grey",
                          disabled: !_vm.canRegister,
                          processing: _vm.registrationIndex > -1,
                        },
                        on: { clickButton: _vm.createMovies },
                      },
                      [_vm._v("一括登録を実行")]
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.movies, function (movie, key) {
                  return [
                    _c("MovieBulkAddItem", {
                      key: key,
                      ref: "item",
                      refInFor: true,
                      attrs: { registerStatus: _vm.resultPromises[key] },
                      on: {
                        onErrorUpdate: function ($event) {
                          return _vm.onErrorUpdate(key, $event)
                        },
                      },
                      model: {
                        value: _vm.movies[key],
                        callback: function ($$v) {
                          _vm.$set(_vm.movies, key, $$v)
                        },
                        expression: "movies[key]",
                      },
                    }),
                  ]
                }),
                _c(
                  "div",
                  { staticClass: "w-6/12 px-4 my-6 mx-auto" },
                  [
                    _c(
                      "LinkButton",
                      {
                        attrs: {
                          classList:
                            "px-2 py-4 text-center text-lg bg-very-base-yallow disabled:bg-very-light-grey",
                          disabled: !_vm.canRegister,
                          processing: _vm.registrationIndex > -1,
                        },
                        on: { clickButton: _vm.createMovies },
                      },
                      [_vm._v("一括登録を実行")]
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
        _vm.movies.length > 0
          ? _c(
              "div",
              { staticClass: "w-6/12 px-4 mt-6 mx-auto" },
              [
                _c(
                  "LinkButton",
                  {
                    attrs: {
                      classList:
                        "px-2 py-4 text-center text-lg bg-light-yellow",
                    },
                    on: {
                      clickButton: function ($event) {
                        return _vm.$router.push("/movies")
                      },
                    },
                  },
                  [_vm._v("登録中の映画作品一覧")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "mb-10" }),
      ]),
      _c("NoticeModal", {
        attrs: {
          modalName: "createSuccessMovies",
          contents: _vm.noticeMessage,
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "grid-style−12 mt-6 tracking-wider" }, [
      _c(
        "h2",
        {
          staticClass:
            "col-span-3 text-4xl text-left font-semibold tracking-wide",
        },
        [_vm._v("一括登録")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w_55 pl-4 mt-10 mx-auto flex" }, [
      _c(
        "div",
        {
          staticClass:
            "bg-base-yellow rounded-full h-6 w-6 flex items-center justify-center text-xs font-semibold",
        },
        [_vm._v("1")]
      ),
      _c("div", { staticClass: "ml-2 font-semibold text-sm" }, [
        _vm._v("下記よりエクセルファイルをダウンロードしてください。"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w_55 pl-4 mt-12 mx-auto flex" }, [
      _c(
        "div",
        {
          staticClass:
            "bg-base-yellow rounded-full h-6 w-6 flex items-center justify-center text-xs font-semibold",
        },
        [_vm._v("2")]
      ),
      _c("div", { staticClass: "ml-2 font-semibold text-sm" }, [
        _vm._v("ダウンロードしたエクセルファイルに必要事項をご記入ください"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w_55 pl-4 mt-14 mx-auto flex" }, [
      _c(
        "div",
        {
          staticClass:
            "bg-base-yellow rounded-full h-6 w-6 flex items-center justify-center text-xs font-semibold",
        },
        [_vm._v("3")]
      ),
      _c("div", { staticClass: "ml-2 font-semibold text-sm" }, [
        _vm._v("入力したエクセルファイルを下記よりアップロードしてください。"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-4 flex items-center justify-center" },
      [
        _c("img", {
          staticClass: "h-48 w-48 ml-8 py-1",
          attrs: { src: require("@/assets/image/excel_logo.svg") },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-5/12 mb-2 mx-auto flex items-center justify-center" },
      [
        _c("img", {
          staticClass: "h-12 w-12",
          attrs: { src: require("@/assets/image/upload.svg") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }