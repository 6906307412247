var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-4 mx-4" }, [
    _c(
      "button",
      {
        staticClass:
          "w-full py-4 border boder-b-0 border-very-light-grey flex items-center justify-between disabled:bg-green-100",
        class: _vm.registerStatus === "fail" ? "bg-red-100" : "",
        attrs: { disabled: _vm.registerStatus === "success" },
        on: {
          click: function ($event) {
            return _vm.toggleField()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "w-8/12 flex justify-start" },
          [
            _vm.registerStatus
              ? [
                  _vm.registerStatus === "success"
                    ? _c("img", {
                        staticClass: "w-6 h-6 mx-2",
                        attrs: {
                          src: require("@/assets/image/check_ok_icon.svg"),
                        },
                      })
                    : _vm.registerStatus === "fail"
                    ? _c("img", {
                        staticClass: "w-6 h-6 mx-2",
                        attrs: { src: require("@/assets/image/ng_icon.svg") },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.hasErrors
              ? _c("img", {
                  staticClass: "w-6 h-6 mx-2",
                  attrs: { src: require("@/assets/image/alert_icon.svg") },
                })
              : _vm.isImpersonated && _vm.isNullFeaturedImage
              ? _c("img", {
                  staticClass: "w-6 h-6 mx-2",
                  attrs: { src: require("@/assets/image/warning_icon2.svg") },
                })
              : _vm.isNullFeaturedImage
              ? _c("img", {
                  staticClass: "w-6 h-6 mx-2",
                  attrs: { src: require("@/assets/image/warning_icon.svg") },
                })
              : _c("img", {
                  staticClass: "w-6 h-6 mx-2",
                  attrs: { src: require("@/assets/image/ok_icon.svg") },
                }),
            _c("span", { staticClass: "mx-2 text-lg font-bold" }, [
              _vm._v(_vm._s(_vm.movieName)),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "w-3/12 flex justify-end" }, [
          _vm.isOpenField
            ? _c("img", {
                staticClass: "w-6 h-6 mx-2",
                attrs: { src: require("@/assets/image/close_field_icon.svg") },
              })
            : _c("img", {
                staticClass: "w-6 h-6 mx-2",
                attrs: { src: require("@/assets/image/open_field_icon.svg") },
              }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpenField,
            expression: "isOpenField",
          },
        ],
        staticClass: "border boder-t-0 border-very-light-grey px-2 text-sm",
      },
      [
        _c("MovieDetailMenu", {
          attrs: {
            activeMenu: _vm.activeMenu,
            errors: _vm.errors,
            movie: _vm.value,
          },
          on: { "active-page": _vm.changeActive },
        }),
        _c("div", { staticClass: "border-b border-brown-grey" }),
        _c(
          "ValidationObserver",
          {
            ref: "movieForm",
            staticClass: "movie_detail container mx-auto pt-4 mb-10",
            attrs: { tag: "div" },
          },
          [
            _c(
              "keep-alive",
              [
                _c(_vm.activeMenu, {
                  tag: "component",
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }